.CalendarDay__selected_span {
  background: #b5dcea;
  color: black;
  border: 1px solid #b5dcea;
}

.CalendarDay__selected {
  background: #49acd0;
  color: white;
  border: 1px solid #49acd0;
}

.CalendarDay__selected_span:hover {
  background: #388aa9;
  color: white;
  border: 1px solid #388aa9;
}

.CalendarDay__selected:hover {
  background: #388aa9;
  color: white;
  border: 1px solid #388aa9;
}

.DateInput_input {
  color: #6d777a;
}

.DateInput_input__focused {
  border-bottom: 2px solid #49acd0;
}
