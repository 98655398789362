/** Font */
$base-font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI",
  "Helvetica Neue", "Helvetica", "Hiragino Sans", "Arial",
  "Hiragino Kaku Gothic ProN", "Meiryo", "Segoe UI Symbol", sans-serif;

/** Sizes */
$site-max-width: 1360px;
$medium-screen-size: 560px;
$large-screen-size: 960px;
$side-pane-width: 200px;

:root {
  /** Z Index */
  --z-index-dropdown: 5;
  --z-index-side-pane: 10;
  --z-index-modal: 100;
  --z-index-toast: 999;

  /** Radius */
  --border-radius: 6px;
  --border-radius-button: 4px;

  /** Fonts */
  --font-weight-default: 300;

  /** Pallet */
  --color-darkGray: #959ba5;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-teal-dark: #226c72;
  --color-teal: #2a878f;
  --color-teal-light: #7fb7bc;
  --color-teal-lightest: #d4e7e9;
  --color-cyan-dark: #6eb6bc;
  --color-cyan: #89e3eb;
  --color-cyan-light: #b8eef3;
  --color-cyan-lightest: #e7f9fb;
  --color-darkgray-dark: #4c4c4c;
  --color-darkgray: #4f596a;
  --color-darkgray-light: #777777;
  --color-darkgray-lightest: #e6e6eb;
  --color-gray-dark: #434343;
  --color-gray: #8a8e90;
  --color-gray-light: #869195;
  --color-gray-lightest: #ededed;
  --color-lightgray: #ddd;
  --color-lightgray-light: #eee;
  --color-lightgray-lightest: #f8f8f8;
  --color-green: #28a745;
  --color-green-light: #7eca8f;
  --color-green-lightest: #d4edda;
  --color-red-dark: #b44343;
  --color-red: #e15454;
  --color-red-light: #ed9898;
  --color-red-lightest: #f9dddd;
  --color-orange: #e38017;
  --color-orange-light: #ffd079;
  --color-orange-lightest: #ffefd2;
  --color-purple: #735ade;
  --color-purple-light: #ab9ceb;
  --color-purple-lightest: #f3f6fd;
  --color-google-brand: #4285f4;
  --color-coversion-green: #00bc14;
  --color-brown: #212a30;
  --color-clear-blue: #49acd0;
  --color-clear-white: #fafcfd;
  --color-dark-blue: #051986;
  --color-water-blue: #95a8e2;
  --color-light-blue: #8ca8cd;
  --color-light-gray: #a3abaf;
  --color-lightest-gray: #f4f8f9;
  --color-blue: #1c98c5;
  --color-yellow: #fbcc3c;
  --color-buzz-black: #203a45;

  /** Semantic */
  --color-primary: var(--color-blue);
  --color-cv: var(--color-orange);

  --color-bg-header: #eff4f6;
  --color-text-header-list: #1e1e1e;
  --color-text-mail-address-header-list: #869195;

  --color-secondary: var(--color-blue);
  --color-light-primary: var(--color-clear-blue);
  --color-light-secondary: var(--color-clear-white);
  --color-light-text-primary: var(--color-light-blue);
  --color-light-text-secondary: var(--color-light-gray);
  --color-text-default: var(--color-darkgray-dark);
  --color-text-side-pane: #929292;
  --color-text-side-pane-active: #28a6dd;
  --color-text-dashboard-operational-card: #636262;
  --color-background-secondary: var(--color-purple-lightest);
  --color-text-white: #fcfdfe;
  --color-text-black: #101111;
  --color-text-sub: var(--color-darkgray);
  --color-text-gray: var(--color-gray-light);
  --color-text-placeholder: #6d777a;
  --color-text-password-rule: #6d777a;
  --color-text-error: var(--color-red);
  --color-border-default: var(--color-lightgray);
  --color-bg-default: var(--color-lightgray-lightest);
  --color-bg-side-pane: #eef0f1;
  --color-bg-side-pane-active: #e3f2fd;
  --color-bg-signin-and-signup-left-area: #ebf7ff;
  --color-bg-llm-reply-area: #aab3b8;
  --color-button-primary: #49acd0;
  --color-button-primary-hover: var(--color-teal-dark);
  --color-button-primary-disabled: var(--color-teal-light);
  --color-button-danger: var(--color-red);
  --color-button-danger-hover: var(--color-red-dark);
  --color-button-danger-disabled: var(--color-red-light);
  --color-button-text-gray: var(--color-darkgray-light);
  --color-bg-button-text-gray-hover: var(--color-lightgray-light);
  --color-text-table-header: var(--color-darkgray-light);
  --color-surface: #1d0505;
  --color-surface-hovered: #f6f6f7;
  --color-surface-pressed: #f1f2f3;
  --color-divider: #e1e3e5;
  --color-link: var(--color-blue);
  --color-facebook: #3b5998;
  --color-registerd-contact-status: #dd3e28;
  --color-contacted-contact-status: #40b361;
  --color-contracted-contact-status: #ff8c00;
  --color-paid-contact-status: #2766b3;
  --color-pending-contact-status: #6a6e71;
  --color-border-primary: #d4dfe6;
  --color-border-gray: #6d777a;
  --color-high-light-off-icon: #fcfdfe;
  --color-border-counter: #6d777a;
  --color-tab-icon: #6d777a;
  --color-title-edit-icon: #6d777a;
  --color-saving-contact-status: #101111;
  --color-failed-contact-status: #f0585d;
  --color-saved-contact-status: #1cc59e;
  --color-content-kind-status: #1c98c5;

  --color-post-template-kind: #dd3e28;
  --color-dm-template-kind: #40b361;
  /** Space */
  --space-1: 5px;
  --space-2: 10px;
  --space-3: 15px;
  --space-4: 20px;
  --space-5: 25px;
  --space-6: 30px;
  --space-7: 35px;
  --space-8: 40px;

  /** Border */
  --border-divider: 1px solid var(--color-divider);

  /** Shadow */
  --shadow-button: 0 4px 16px rgba(5, 0, 56, 0.1);
  --shadow-box: 0 0 5px rgba(5, 0, 56, 0.02), 0 1px 2px rgba(5, 0, 56, 0.05);
  --shadow-card: 0 0 5px rgba(5, 0, 56, 0.02), 0 1px 2px rgba(5, 0, 56, 0.05);

  /** Sizes */
  --site-max-width: 1360px;
  --medium-screen-size: 560px;
  --large-screen-size: 960px;
  --side-pane-width: 200px;

  /** Font sizes */
  --font-size-heading-large: 24px;
  --font-size-heading-medium: 20px;
  --font-size-heading-small: 18px;

  --font-size-label-large: 16px;
  --font-size-label-medium: 14px;
  --font-size-label-small: 12px;
  --font-size-label-xsmall: 11px;

  --font-size-body-large: 16px;
  --font-size-body-medium: 14px;
  --font-size-body-small: 12px;
  --font-size-body-xsmall: 11px;

  /** Font weights */
  --font-weight-bold: 800;
  --font-weight-medium: 600;
  --font-weight-regular: 400;
}
